body {
  margin: 0;
  font-family: neusa-next-std, sans-serif;
  font-weight: 400;
  font-style: normal;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

.font-condensed {
  font-family: neusa-next-std-condensed, sans-serif;
  font-weight: 700;
  font-style: normal;
}



@keyframes moveBackground {
  0% {
    background-position: 100 0;
  }
  50% {
    background-position: -200px 0;
  }
  100% {
    background-position: 0 0;
  }
}